<script>
import Repository from "@/repositories/Repository.vue";
import SSORepository from "@/repositories/SSO/Repository.vue";
import Enum from "@/helpers/Enum.vue";
import LosRepository from "@/repositories/Los/Repository.vue";
import RepositoryMifos from "@/repositories/Mifos/Repository.vue";
//Lấy TPGD theo mã PGD
const GetShopManagerByCode = async (shopCode) => {
  return await SSORepository.get(Enum.API.SSO.ShopManagerByCode + `?code=${shopCode}`);
};

//Lấy ds mệnh giá
const GetListCategoryDenominationsMoney = async () => {
  return await Repository.get(Enum.API.Category.ListCategoryDenominationsMoney);
};

const CreateInventorySec = async (model) => {
  return await Repository.post(Enum.API.InventorySec.Create, model);
};

const Post_GetInventorySecs = async (filter) => {
  return await Repository.post(Enum.API.InventorySec.Get, filter);
}

const Put_UpdateInventorySecApporve = async (model) => {
  return await Repository.update(Enum.API.InventorySec.Update, model);
}

const Put_UpdateUploadImages = async (model) => {
  return await Repository.update(Enum.API.InventorySec.UploadImages, model);
}

const Post_ExportExcel = async (filter) => {
  return await Repository.postDownload(Enum.API.InventorySec.ExportExcel, filter);
}

//Lấy số dư chênh lệch thu/chi bên Thu/chi
const Post_GetMoneyDifferenceByEmployee = async (model) => {
  return await LosRepository.post(Enum.API.Los.GetMoneyDifferenceByEmployee, model);
}

//Lấy số dư Tạm ứng/Hoàn tạm ứng chờ duyệt
const Post_GetMoneyAdvanceDifferenceByEmployee = async (model) => {
  return await LosRepository.post(Enum.API.Los.GetMoneyAdvanceDifferenceByEmployee, model);
}

///Lấy danh sách thu chi HĐ chờ xử lý sum
const Get_ListRevPending= async(shopCode,fromDate,toDate,userName)=>{
  return await LosRepository.get(Enum.API.Los.GetListRevPending + `?shopCode=${shopCode}&fromDate=${fromDate}&toDate=${toDate}&User=${userName}`);
}


///Lấy danh sách thu chi HĐ chờ xử lý sum
const Get_ListIsLastInven= async(shopCode)=>{
  return await Repository.get(Enum.API.InventorySec.ListIsLast + `?shopCode=${shopCode}`);
}


const Get_ListRevErrorCategory=async()=>{
  return await Repository.get(Enum.API.Category.ListRevError)
}

const Post_ExportExcel_One = async (filter) => {
  return await Repository.postDownload(Enum.API.InventorySec.Export, filter);
}
const Get_BalanceOffice = async(filter)=>{
  return await RepositoryMifos.post(Enum.API.Mifos.GetFundBalanceOffice, filter);
}

///Lấy danh sách thu chi HĐ chờ xử lý sum
const Get_ListAdvancePending= async (item) => {

  return await LosRepository.post(Enum.API.Los.GetListAdvancePending ,item);
}
  


const Post_ExportExcelAdvancePending = async (filter) => {
  return await LosRepository.postDownload(Enum.API.Los.Export, filter);
}


const Get_GetById= async(id)=>{
  return await Repository.get(Enum.API.InventorySec.GetById + `?id=${id}`);
}
export default {
    GetShopManagerByCode,
    GetListCategoryDenominationsMoney,
    CreateInventorySec,
    Post_GetInventorySecs,
    Put_UpdateInventorySecApporve,
    Put_UpdateUploadImages,
    Post_ExportExcel,
    Post_GetMoneyDifferenceByEmployee,
    Post_GetMoneyAdvanceDifferenceByEmployee,
    Get_ListRevPending,
    Get_ListIsLastInven,
    Get_ListRevErrorCategory,
    Post_ExportExcel_One,
    Get_BalanceOffice,
    Get_ListAdvancePending,
    Post_ExportExcelAdvancePending,
    Get_GetById
}
</script>