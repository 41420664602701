<template>
  <div class="sheet padding-10mm">
    <table style="width: 100%; font-size: 14px" cellpadding="0" cellspacing="0">
      <tbody>
        <tr>
          <td colspan="2" style="text-align: left; padding: 5px 0 0 0">
            <span
              style="
                font-size: 14px;
                font-weight: 400;
                text-transform: uppercase;
                padding-right: 5px;
                line-height: 18px;
              "
              >CÔNG TY CỔ PHẦN KINH DOANH F88</span
            >
            <p style="font-size: 14px; padding-top: 5px">
              {{ fundSecData.Shop.Name + "/" + fundSecData.Shop.Address }}
            </p>
          </td>
        </tr>
        <tr>
          <td
            colspan="2"
            style="
              text-align: left;
              padding: 15px 0 0 0;
              text-align: center;
              font-size: 20px;
              font-weight: 600;
              text-transform: uppercase;
            "
          >
            BIÊN BẢN KIỂM KÊ QUỸ VÀ SÉC TỒN
          </td>
        </tr>
        <tr>
            <td colspan="2" style="text-align: left; padding: 20px 0 0 0">
            <p >Lần kiểm kê số:&nbsp; {{fundSecData.NumberOfInventory}}</p>
          </td>
        </tr>
        <tr>       
          <td colspan="2" style="text-align: left; line-height: 18px">
            <p style="padding: 0; font-size: 14px; line-height: 18px">
              Thời gian kiểm kê : {{ timeStr }} 
            </p>
            <p style="padding: 0; font-size: 14px; line-height: 18px">
              Ban kiểm kê gồm :
            </p>
            <p style="padding: 0; font-size: 14px; line-height: 18px">
              - Ông/Bà : <strong>{{ fundSecData.ShopManager.Name }}</strong> -
              Trưởng phòng giao dịch<br />
              - Ông/Bà : <strong>{{ fundSecData.CurrentUser.Name }}</strong> -
              Thủ quỹ
            </p>
            <p style="padding: 12px 0 0 0; font-size: 14px; line-height: 18px">
              Cùng tiến hành kiểm kê quỹ tiền mặt kết quả như sau :
            </p>
          </td>
        </tr>
        <tr>
          <td colspan="2" style="padding-top: 10px">
            <table
              style="width: 100%; border: 1px #555 solid; font-size: 12px"
              cellpadding="0"
              cellspacing="0"
            >
              <thead>
                <tr>
                  <th
                    style="
                      padding: 4px;
                      font-size: 12px;
                      text-align: center;
                      border-bottom: 1px #555 solid;
                      background: #aaa;
                    "
                    colspan="3"
                  >
                    Kiểm kê séc tồn
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td
                    style="
                      padding: 4px;
                      border: 1px #555 solid;
                      border-width: 0px 1px 1px 0px;
                      text-align: center;
                    "
                  >
                    STT
                  </td>
                  <td
                    style="
                      padding: 4px;
                      border: 1px #555 solid;
                      border-width: 0px 1px 1px 0px;
                      text-align: center;
                    "
                  >
                    Diễn giải
                  </td>
                  <td
                    style="
                      padding: 5px;
                      border: 1px #555 solid;
                      border-width: 0px 0px 1px 0px;
                      text-align: center;
                    "
                  >
                    Số lượng (Tờ)
                  </td>
                </tr>
                <tr>
                  <td
                    style="
                      padding: 4px;
                      border: 1px #555 solid;
                      border-width: 0px 1px 0px 0px;
                      text-align: center;
                    "
                  >
                    #
                  </td>
                  <td
                    style="
                      padding: 4px;
                      border: 1px #555 solid;
                      border-width: 0px 1px 0px 0px;
                      text-align: center;
                    "
                  >
                    Số lượng séc tồn
                  </td>
                  <td
                    style="
                      padding: 5px;
                      border: 1px #555 solid;
                      border-width: 0px 0px 0px 0px;
                      text-align: center;
                    "
                  >
                    <strong>{{ fundSecData.TotalNumOfSec }}</strong>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
         <tr style="padding-top: 10px">
            <td colspan="2">
             <table
              style="width: 100%; border:  1px #555 solid; font-size: 12px"
              cellpadding="0"
              cellspacing="0"
            >
              <thead>
                <tr>
                  <th
                    style="
                      padding: 4px;
                      font-size: 12px;
                      text-align: left;
                      border-bottom: 1px #555 solid;
                      background: #aaa;
                    "
                    colspan="10"
                  >
                    Tổng kết quỹ
                  </th>

                </tr>
                   <tr>
                  <td
                    style="
                      padding: 4px;
                      border: 1px #555 solid;
                      border-width: 0px 1px 1px 0px;
                      text-align: center;
                     
                    "
                  >
                    Tài khoản
                  </td>
                  <td
                    style="
                      padding: 4px;
                      border: 1px #555 solid;
                      border-width: 0px 1px 1px 0px;
                      text-align: center;
                     
                    "
                  >
                    Số dư quỹ tại thời điểm KK(1)
                  </td>
                  <td
                    style="
                      padding: 5px;
                      border: 1px #555 solid;
                      border-width: 0px 1px 1px 0px;
                      text-align: center;
                      
                    "
                  >
                    Số dư quỹ theo BBKK(2)
                  </td>
                      <td
                    style="
                      padding: 5px;
                      border: 1px #555 solid;
                      border-width: 0px 1px 1px 0px;
                      text-align: center;
                    "
                  >
                    Số dư thực tế kiểm kê (3)
                  </td>
                     <td
                    style="
                      padding: 5px;
                      border: 1px #555 solid;
                      border-width: 0px 1px 1px 0px;
                      text-align: center;
                    "
                  >
                    Chi chờ xử lí (4)   
                  </td>
                       <td
                    style="
                      padding: 5px;
                      border: 1px #555 solid;
                      border-width: 0px 1px 1px 0px;
                      text-align: center;
                    "
                  >
                    Thu chờ xử lí (5)  
                  </td>
                         <td
                    style="
                      padding: 5px;
                      border: 1px #555 solid;
                      border-width: 0px 1px 1px 0px;
                      text-align: center;
                    "
                  >
                    Quỹ thực tế so với sổ sách (6  = 3 - 2 )  
                  </td>
                        <td
                    style="
                      padding: 5px;
                      border: 1px #555 solid;
                      border-width: 0px 1px 1px 0px;
                      text-align: center;
                    "
                  >
                    Lệch (7=3-2+4-5) 
                  </td>
                  <td
                    style="
                      padding: 5px;
                      border: 1px #555 solid;
                      border-width: 0px 1px 1px 0px;
                      text-align: center;
                    "
                  >
                    Thời gian kiểm kê
                  </td>
                   <td
                    style="
                      padding: 5px;
                      border: 1px #555 solid;
                      border-width: 0px 0px 1px 0px;
                      text-align: center;
                    "
                  >
                    Trạng thái user
                  </td>
                </tr>
              </thead>
              <tbody v-if="fundSecData.InventoryTotal.length>0">
                <!-- <tr>
                                <td style="padding:5px; border:1px #555 solid; border-width: 0px 1px 1px 0px; text-align:center;">A</td>
                                <td style="padding:5px; border:1px #555 solid; border-width: 0px 1px 1px 0px;">B</td>
                                <td style="padding:5px; border:1px #555 solid; border-width: 0px 1px 1px 0px; text-align:center;">1</td>
                                <td style="padding:5px; border:1px #555 solid; border-width: 0px 0px 1px 0px; text-align:right;">2</td>
                            </tr> -->
                <tr
                  v-for="(item, idx) in fundSecData.InventoryTotal"
                  :key="idx"
               :style="fundSecData.InventoryTotal.length-1==idx?'font-weight:bold;':''" >
                  <td
                    style="
                      padding: 5px;
                      border: 1px #555 solid;
                      border-width: 0px 1px 1px 0px;
                      text-align: left;
                    "
            
                  >
                    {{item.FullName}}
                  </td>
                  <td
                    style="
                      padding: 5px;
                      border: 1px #555 solid;
                      border-width: 0px 1px 1px 0px;
                       text-align: right;
                    "
                  >
                    {{formatCurrency(item.FundRealTime)}}
                  </td>
                  <td
                    style="
                      padding: 5px;
                      border: 1px #555 solid;
                      border-width: 0px 1px 1px 0px;
                      text-align: right;
                    "
                  >
                    {{ formatCurrency(item.TotalMoneyCashNote) }}
                  </td>
                      <td
                    style="
                      padding: 5px;
                      border: 1px #555 solid;
                      border-width: 0px 1px 1px 0px;
                      text-align: right;
                    "
                  >
                    {{ formatCurrency(item.TotalMoneyActual) }}
                  </td>
                               <td
                    style="
                      padding: 5px;
                      border: 1px #555 solid;
                      border-width: 0px 1px 1px 0px;
                      text-align: right;
                    "
                  >
                    {{ formatCurrency(item.Collect) }}
                  </td>
                            <td
                    style="
                      padding: 5px;
                      border: 1px #555 solid;
                      border-width: 0px 1px 1px 0px;
                      text-align: right;
                    "
                  >
                    {{ formatCurrency(item.Spend) }}
                  </td>
                   <td
                    style="
                      padding: 5px;
                      border: 1px #555 solid;
                      border-width: 0px 1px 1px 0px;
                      text-align: right;
                    "
                  >
                    {{ formatCurrency(item.TotalReality) }}
                  </td>
                         <td
                    style="
                      padding: 5px;
                      border: 1px #555 solid;
                      border-width: 0px 1px 1px 0px;
                      text-align: right;
                    "
                  >
                    {{ formatCurrency(item.TotalDeviant) }}
                  </td>
                             <td
                    style="
                      padding: 5px;
                      border: 1px #555 solid;
                      border-width: 0px 1px 1px 0px;
                      text-align: center;
                    "
                  >
                    {{ ConvertDateTime.ConvertUnixToDatetime(item.CreatedDate)}}
                  </td>
                  <td
                    style="
                      padding: 5px;
                      border: 1px #555 solid;
                      border-width: 0px 0px 1px 0px;
                      text-align: center;
                    "
                  >
                    {{ item.Active }}     
                  </td>
                </tr>
              </tbody>
            </table>
            </td>
   
          </tr >
           <tr style="padding-top: 10px">
            <td colspan="2">
             <table
              style="width: 100%; border:  1px #555 solid; font-size: 12px"
              cellpadding="0"
              cellspacing="0"
            >
              <thead>
                <tr>
                  <th
                    style="
                      padding: 4px;
                      font-size: 12px;
                      text-align: left;
                      border-bottom: 1px #555 solid;
                      background: #aaa;
                    "
                    colspan="2"
                  >
                    Chuyển quỹ chờ xác nhận
                  </th>
                          <th
                    style="
                      padding: 4px;
                      font-size: 12px;
                      text-align: right;
                      border-bottom: 1px #555 solid;
                      background: #aaa;
                    "
                    colspan="2"
                  >
                    {{formatCurrency(fundSecData.TotalMoneyNotApcept)}}
                  </th>
                </tr>
                   <tr>
                  <td
                    style="
                      padding: 4px;
                      border: 1px #555 solid;
                      border-width: 0px 1px 1px 0px;
                      text-align: center;
                      width:50px
                    "
                  >
                    STT
                  </td>
                  <td
                    style="
                      padding: 4px;
                      border: 1px #555 solid;
                      border-width: 0px 1px 1px 0px;
                      text-align: center;
                      width:200px
                    "
                  >
                    Nhân viên
                  </td>
                  <td
                    style="
                      padding: 5px;
                      border: 1px #555 solid;
                      border-width: 0px 1px 1px 0px;
                      text-align: right;
                      width:100px
                    "
                  >
                    Số tiền
                  </td>
                      <td
                    style="
                      padding: 5px;
                      border: 1px #555 solid;
                      border-width: 0px 0px 1px 0px;
                      text-align: center;
                    "
                  >
                    Giờ tạo lệnh
                  </td>
                </tr>
              </thead>
              <tbody v-if="fundSecData.FundWaitting.length>0">
                <!-- <tr>
                                <td style="padding:5px; border:1px #555 solid; border-width: 0px 1px 1px 0px; text-align:center;">A</td>
                                <td style="padding:5px; border:1px #555 solid; border-width: 0px 1px 1px 0px;">B</td>
                                <td style="padding:5px; border:1px #555 solid; border-width: 0px 1px 1px 0px; text-align:center;">1</td>
                                <td style="padding:5px; border:1px #555 solid; border-width: 0px 0px 1px 0px; text-align:right;">2</td>
                            </tr> -->
                <tr
                  v-for="(item, idx) in fundSecData.FundWaitting"
                  :key="idx"
                >
                  <td
                    style="
                      padding: 5px;
                      border: 1px #555 solid;
                      border-width: 0px 1px 1px 0px;
                      text-align: center;
                    "
                  >
                    {{ idx + 1 }}
                  </td>
                  <td
                    style="
                      padding: 5px;
                      border: 1px #555 solid;
                      border-width: 0px 1px 1px 0px;
                    "
                  >
                    {{item.CreatedFullName}}
                  </td>
                  <td
                    style="
                      padding: 5px;
                      border: 1px #555 solid;
                      border-width: 0px 1px 1px 0px;
                      text-align: right;
                    "
                  >
                    {{ formatCurrency(item.Total) }}
                  </td>
                  <td
                    style="
                      padding: 5px;
                      border: 1px #555 solid;
                      border-width: 0px 0px 1px 0px;
                      text-align: center;
                    "
                  >
                    {{ ConvertDateTime.ConvertUnixToDatetime(item.CreatedDate) }}     
                  </td>
                </tr>
              </tbody>
            </table>
            </td>
   
          </tr >
      <tr style="padding-top: 10px">
            <td colspan="2">
             <table
              style="width: 100%; border:  1px #555 solid; font-size: 12px"
              cellpadding="0"
              cellspacing="0"
            >
              <thead>
                <tr>
                  <th
                    style="
                      padding: 4px;
                      font-size: 12px;
                      text-align: left;
                      border-bottom: 1px #555 solid;
                      background: #aaa;
                    "
                    colspan="2"
                  >
                    Lệnh chi chờ xử lý
                  </th>
                          <th
                    style="
                      padding: 4px;
                      font-size: 12px;
                      text-align: right;
                      border-bottom: 1px #555 solid;
                      background: #aaa;
                    "
                    colspan="2"
                  >
                    {{formatCurrency(fundSecData.Collect)}}
                  </th>
                </tr>
                   <tr>
                  <td
                    style="
                      padding: 4px;
                      border: 1px #555 solid;
                      border-width: 0px 1px 1px 0px;
                      text-align: center;
                      width:50px
                    "
                  >
                    STT
                  </td>
                  <td
                    style="
                      padding: 4px;
                      border: 1px #555 solid;
                      border-width: 0px 1px 1px 0px;
                      text-align: center;
                      width:200px
                    "
                  >
                    Loại thu/chi
                  </td>
                  <td
                    style="
                      padding: 5px;
                      border: 1px #555 solid;
                      border-width: 0px 1px 1px 0px;
                      text-align: center;
                      width:100px
                    "
                  >
                    Số tiền
                  </td>
                      <td
                    style="
                      padding: 5px;
                      border: 1px #555 solid;
                      border-width: 0px 0px 1px 0px;
                      text-align: center;
                    "
                  >
                    Giờ tạo lệnh
                  </td>
                </tr>
              </thead>
              <tbody v-if="collect.length>0">
                <!-- <tr>
                                <td style="padding:5px; border:1px #555 solid; border-width: 0px 1px 1px 0px; text-align:center;">A</td>
                                <td style="padding:5px; border:1px #555 solid; border-width: 0px 1px 1px 0px;">B</td>
                                <td style="padding:5px; border:1px #555 solid; border-width: 0px 1px 1px 0px; text-align:center;">1</td>
                                <td style="padding:5px; border:1px #555 solid; border-width: 0px 0px 1px 0px; text-align:right;">2</td>
                            </tr> -->
                <tr
                  v-for="(item, idx) in collect"
                  :key="idx"
                >
                  <td
                    style="
                      padding: 5px;
                      border: 1px #555 solid;
                      border-width: 0px 1px 1px 0px;
                      text-align: center;
                    "
                  >
                    {{ idx + 1 }}
                  </td>
                  <td
                    style="
                      padding: 5px;
                      border: 1px #555 solid;
                      border-width: 0px 1px 1px 0px;
                    "
                  >
                    {{item.TypeTransaction.Name}}
                  </td>
                  <td
                    style="
                      padding: 5px;
                      border: 1px #555 solid;
                      border-width: 0px 1px 1px 0px;
                      text-align: right;
                    "
                  >
                    {{ formatCurrency(item.RealMoney) }}
                  </td>
                  <td
                    style="
                      padding: 5px;
                      border: 1px #555 solid;
                      border-width: 0px 0px 1px 0px;
                      text-align: center;
                    "
                  >
                    {{ ConvertDateTime.ConvertUnixToDatetime(item.CreatedDate) }}     
                  </td>
                </tr>
              </tbody>
            </table>
            </td>
   
          </tr >
           <tr  style="margin-top:10px">
            <td colspan="2">
             <table
              style="width: 100%; border: 1px #555 solid; font-size: 12px"
              cellpadding="0"
              cellspacing="0"
            >
              <thead>
                <tr>
                  <th
                    style="
                      padding: 4px;
                      font-size: 12px;
                      text-align: left;
                      border-bottom: 1px #555 solid;
                      background: #aaa;
                    "
                    colspan="2"
                  >
                    Lệnh thu chờ xử lý
                  </th>
                          <th
                    style="
                      padding: 4px;
                      font-size: 12px;
                      text-align: right;
                      border-bottom: 1px #555 solid;
                      background: #aaa;
                    "
                    colspan="2"
                  >
                    {{formatCurrency(fundSecData.Spend)}}
                  </th>
                </tr>
                   <tr>
                  <td
                    style="
                      padding: 4px;
                      border: 1px #555 solid;
                      border-width: 0px 1px 1px 0px;
                      text-align: center;
                      width:50px
                    "
                  >
                    STT
                  </td>
                  <td
                    style="
                      padding: 4px;
                      border: 1px #555 solid;
                      border-width: 0px 1px 1px 0px;
                      text-align: center;
                      width:200px
                    "
                  >
                    Loại thu/chi
                  </td>
                  <td
                    style="
                      padding: 5px;
                      border: 1px #555 solid;
                      border-width: 0px 1px 1px 0px;
                      text-align: center;
                      width:100px
                    "
                  >
                    Số tiền
                  </td>
                      <td
                    style="
                      padding: 5px;
                      border: 1px #555 solid;
                      border-width: 0px 0px 1px 0px;
                      text-align: center;
                    "
                  >
                    Giờ tạo lệnh
                  </td>
                </tr>
              </thead>
              <tbody v-if="spend.length>0">
                <!-- <tr>
                                <td style="padding:5px; border:1px #555 solid; border-width: 0px 1px 1px 0px; text-align:center;">A</td>
                                <td style="padding:5px; border:1px #555 solid; border-width: 0px 1px 1px 0px;">B</td>
                                <td style="padding:5px; border:1px #555 solid; border-width: 0px 1px 1px 0px; text-align:center;">1</td>
                                <td style="padding:5px; border:1px #555 solid; border-width: 0px 0px 1px 0px; text-align:right;">2</td>
                            </tr> -->
                <tr
                  v-for="(item, idx) in spend"
                  :key="idx"
                >
                   <td
                    style="
                      padding: 5px;
                      border: 1px #555 solid;
                      border-width: 0px 1px 1px 0px;
                      text-align: center;
                    "
                  >
                    {{ idx + 1 }}
                  </td>
                  <td
                    style="
                      padding: 5px;
                      border: 1px #555 solid;
                      border-width: 0px 1px 1px 0px;
                    "
                  >
                    {{item.TypeTransaction.Name}}
                  </td>
                  <td
                    style="
                      padding: 5px;
                      border: 1px #555 solid;
                      border-width: 0px 1px 1px 0px;
                      text-align: right;
                    "
                  >
                    {{ formatCurrency(item.RealMoney) }}
                  </td>
                  <td
                    style="
                      padding: 5px;
                      border: 1px #555 solid;
                      border-width: 0px 0px 1px 0px;
                      text-align: center;
                    "
                  >
                    {{ ConvertDateTime.ConvertUnixToDatetime(item.CreatedDate) }}     
                  </td>
                </tr>
              </tbody>
            </table>
            </td>
   
          </tr>
             <tr style="margin-top:10px">
            <td colspan="2">
             <table
              style="width: 100%; border: 1px #555 solid; font-size: 12px"
              cellpadding="0"
              cellspacing="0"
            >
              <thead>
                <tr>
                  <th
                    style="
                      padding: 4px;
                      font-size: 12px;
                      text-align: left;
                      border-bottom: 1px #555 solid;
                      background: #aaa;
                    "
                    colspan="2"
                  >
                    Tổng số tiền thiếu
                  </th>
                          <th
                    style="
                      padding: 4px;
                      font-size: 12px;
                      text-align: right;
                      border-bottom: 1px #555 solid;
                      background: #aaa;
                    "
                    colspan="2"
                  >
                    {{formatCurrency(SpendErrorTotal)}}
                  </th>
                </tr>
                   <tr>
                  <td
                    style="
                      padding: 4px;
                      border: 1px #555 solid;
                      border-width: 0px 1px 1px 0px;
                      text-align: center;
                       width:50px
                    "
                  >
                    STT
                  </td>
                  <td
                    style="
                      padding: 4px;
                      border: 1px #555 solid;
                      border-width: 0px 1px 1px 0px;
                      text-align: center;
                    width:200px
                    "
                  >
                    Loại lỗi
                  </td>
                  <td
                    style="
                      padding: 5px;
                      border: 1px #555 solid;
                      border-width: 0px 1px 1px 0px;
                      text-align: center;
                     width:100px
                    "
                  >
                    Số tiền
                  </td>
                      <td
                    style="
                      padding: 5px;
                      border: 1px #555 solid;
                      border-width: 0px 0px 1px 0px;
                      text-align: center;
                    "
                  >
                    Ghi chú
                  </td>
                </tr>
              </thead>
              <tbody>
                <!-- <tr>
                                <td style="padding:5px; border:1px #555 solid; border-width: 0px 1px 1px 0px; text-align:center;">A</td>
                                <td style="padding:5px; border:1px #555 solid; border-width: 0px 1px 1px 0px;">B</td>
                                <td style="padding:5px; border:1px #555 solid; border-width: 0px 1px 1px 0px; text-align:center;">1</td>
                                <td style="padding:5px; border:1px #555 solid; border-width: 0px 0px 1px 0px; text-align:right;">2</td>
                            </tr> -->
                <tr
                  v-for="(item, idx) in lstTableSpendError"
                  :key="idx"
                >
                   <td
                    style="
                      padding: 5px;
                      border: 1px #555 solid;
                      border-width: 0px 1px 1px 0px;
                      text-align: center;
                    "
                  >
                    {{ idx + 1 }}
                  </td>
                  <td
                    style="
                      padding: 5px;
                      border: 1px #555 solid;
                      border-width: 0px 1px 1px 0px;
                    "
                  >
                    {{item.SpendErrorChild.Name}}
                  </td>
                  <td
                    style="
                      padding: 5px;
                      border: 1px #555 solid;
                      border-width: 0px 1px 1px 0px;
                      text-align: right;
                    "
                  >
                    {{ formatCurrency(item.Money) }}
                  </td>
                  <td
                    style="
                      padding: 5px;
                      border: 1px #555 solid;
                      border-width: 0px 0px 1px 0px;
                      text-align: center;
                    "
                  >
                    {{ item.Description }}     
                  </td>
                </tr>
              </tbody>
            </table>
            </td>
   
          </tr>
            <tr style="margin-top:10px">
            <td colspan="2">
             <table
              style="width: 100%; border: 1px #555 solid; font-size: 12px"
              cellpadding="0"
              cellspacing="0"
            >
              <thead>
                <tr>
                  <th
                    style="
                      padding: 4px;
                      font-size: 12px;
                      text-align: left;
                      border-bottom: 1px #555 solid;
                      background: #aaa;
                    "
                    colspan="2"
                  >
                    Tổng số tiền thừa
                  </th>
                          <th
                    style="
                      padding: 4px;
                      font-size: 12px;
                      text-align: right;
                      border-bottom: 1px #555 solid;
                      background: #aaa;
                      width:200px
                    "
                    colspan="2"
                  >
                    {{formatCurrency(RevErrorTotal)}}
                  </th>
                </tr>
                   <tr>
                  <td
                    style="
                      padding: 4px;
                      border: 1px #555 solid;
                      border-width: 0px 1px 1px 0px;
                      text-align: center;
                       width:50px
                    "
                  >
                    STT
                  </td>
                  <td
                    style="
                      padding: 4px;
                      border: 1px #555 solid;
                      border-width: 0px 1px 1px 0px;
                      text-align: center;
                      width:200px
                    "
                  >
                    Loại lỗi
                  </td>
                  <td
                    style="
                      padding: 5px;
                      border: 1px #555 solid;
                      border-width: 0px 1px 1px 0px;
                      text-align: center;
                      width:100px
                    "
                  >
                    Số tiền
                  </td>
                      <td
                    style="
                      padding: 5px;
                      border: 1px #555 solid;
                      border-width: 0px 0px 1px 0px;
                      text-align: center;
                    "
                  >
                    Ghi chú
                  </td>
                </tr>
              </thead>
              <tbody>
                <!-- <tr>
                                <td style="padding:5px; border:1px #555 solid; border-width: 0px 1px 1px 0px; text-align:center;">A</td>
                                <td style="padding:5px; border:1px #555 solid; border-width: 0px 1px 1px 0px;">B</td>
                                <td style="padding:5px; border:1px #555 solid; border-width: 0px 1px 1px 0px; text-align:center;">1</td>
                                <td style="padding:5px; border:1px #555 solid; border-width: 0px 0px 1px 0px; text-align:right;">2</td>
                            </tr> -->
                <tr
                  v-for="(item, idx) in lstTableRevError"
                  :key="idx"
                >
                   <td
                    style="
                      padding: 5px;
                      border: 1px #555 solid;
                      border-width: 0px 1px 1px 0px;
                      text-align: center;
                    "
                  >
                    {{ idx + 1 }}
                  </td>
                  <td
                    style="
                      padding: 5px;
                      border: 1px #555 solid;
                      border-width: 0px 1px 1px 0px;
                    "
                  >
                    {{item.SpendErrorChild.Name}}
                  </td>
                  <td
                    style="
                      padding: 5px;
                      border: 1px #555 solid;
                      border-width: 0px 1px 1px 0px;
                      text-align: right;
                    "
                  >
                    {{ formatCurrency(item.Money) }}
                  </td>
                  <td
                    style="
                      padding: 5px;
                      border: 1px #555 solid;
                      border-width: 0px 0px 1px 0px;
                      text-align: center;
                    "
                  >
                    {{ item.Description }}     
                  </td>
                </tr>
              </tbody>
            </table>
            </td>
   
          </tr>
        <tr>
          <td colspan="2" style="padding-top: 10px">
            <table
              style="width: 100%; border: 1px #555 solid; font-size: 12px"
              cellpadding="0"
              cellspacing="0"
            >
              <thead>
                <tr>
                  <th
                    style="
                      padding: 4px;
                      font-size: 12px;
                      text-align: center;
                      border-bottom: 1px #555 solid;
                      background: #aaa;
                    "
                    colspan="4"
                  >
                    Kiểm kê quỹ tiền mặt
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td
                    style="
                      padding: 5px;
                      border: 1px #555 solid;
                      border-width: 0px 1px 1px 0px;
                      text-align: center;
                    "
                  >
                    STT
                  </td>
                  <td
                    style="
                      padding: 5px;
                      border: 1px #555 solid;
                      border-width: 0px 1px 1px 0px;
                    "
                  >
                    Diễn giải
                  </td>
                  <td
                    style="
                      padding: 5px;
                      border: 1px #555 solid;
                      border-width: 0px 1px 1px 0px;
                      text-align: center;
                    "
                  >
                    Số lượng (Tờ)
                  </td>
                  <td
                    style="
                      padding: 5px;
                      border: 1px #555 solid;
                      border-width: 0px 0px 1px 0px;
                      text-align: center;
                    "
                  >
                    Thành tiền
                  </td>
                </tr>
                <!-- <tr>
                                <td style="padding:5px; border:1px #555 solid; border-width: 0px 1px 1px 0px; text-align:center;">A</td>
                                <td style="padding:5px; border:1px #555 solid; border-width: 0px 1px 1px 0px;">B</td>
                                <td style="padding:5px; border:1px #555 solid; border-width: 0px 1px 1px 0px; text-align:center;">1</td>
                                <td style="padding:5px; border:1px #555 solid; border-width: 0px 0px 1px 0px; text-align:right;">2</td>
                            </tr> -->
                <tr
                  v-for="(item, idx) in fundSecData.FundMoneySecList"
                  :key="idx"
                >
                  <td
                    style="
                      padding: 5px;
                      border: 1px #555 solid;
                      border-width: 0px 1px 1px 0px;
                      text-align: center;
                    "
                  >
                    {{ idx + 1 }}
                  </td>
                  <td
                    style="
                      padding: 5px;
                      border: 1px #555 solid;
                      border-width: 0px 1px 1px 0px;
                    "
                  >
                    Trong đó: - Loại {{ formatCurrency(item.Value) }}
                  </td>
                  <td
                    style="
                      padding: 5px;
                      border: 1px #555 solid;
                      border-width: 0px 1px 1px 0px;
                      text-align: center;
                    "
                  >
                    {{ item.NumOfMoney }}
                  </td>
                  <td
                    style="
                      padding: 5px;
                      border: 1px #555 solid;
                      border-width: 0px 0px 1px 0px;
                      text-align: right;
                    "
                  >
                    {{ formatCurrency(item.Value * item.NumOfMoney) }}
                  </td>
                </tr>
                <tr>
                  <td
                    style="
                      padding: 5px;
                      border: 1px #555 solid;
                      border-width: 0px 1px 1px 0px;
                      text-align: right;
                      font-weight: 600;
                    "
                    colspan="3"
                  >
                    I. Số dư sổ quỹ
                  </td>
                  <td
                    style="
                      padding: 5px;
                      border: 1px #555 solid;
                      border-width: 0px 0px 1px 0px;
                      text-align: right;
                      font-weight: 600;
                    "
                  >
                    {{ formatCurrency(fundSecData.TotalMoneyCashNote) }}
                  </td>
                </tr>
                <tr>
                  <td
                    style="
                      padding: 5px;
                      border: 1px #555 solid;
                      border-width: 0px 1px 1px 0px;
                      text-align: right;
                      font-weight: 600;
                    "
                    colspan="3"
                  >
                    II. Số kiểm kê thực tế:
                  </td>
                  <td
                    style="
                      padding: 5px;
                      border: 1px #555 solid;
                      border-width: 0px 0px 1px 0px;
                      text-align: right;
                      font-weight: 600;
                    "
                  >
                    {{ formatCurrency(fundSecData.TotalMoneyActual) }}
                  </td>
                </tr>
                <tr style="display:none">
                  <td
                    style="
                      padding: 5px;
                      border: 1px #555 solid;
                      border-width: 0px 1px 1px 0px;
                      text-align: right;
                      font-weight: 600;
                    "
                    colspan="3"
                  >
                    Số tiền chênh lệch theo thực thu/ thực chi
                  </td>
                  <td
                    style="
                      padding: 5px;
                      border: 1px #555 solid;
                      border-width: 0px 0px 1px 0px;
                      text-align: right;
                      font-weight: 600;
                    "
                  >
                    {{ formatCurrency(fundSecData.TotalMoneyDiff) }}
                  </td>
                </tr>
                <tr style="display:none">
                  <td
                    style="
                      padding: 5px;
                      border: 1px #555 solid;
                      border-width: 0px 1px 1px 0px;
                      text-align: right;
                      font-weight: 600;
                    "
                    colspan="3"
                  >
                    Số tiền chuyển quỹ chưa được tích nhận
                  </td>
                  <td
                    style="
                      padding: 5px;
                      border: 1px #555 solid;
                      border-width: 0px 0px 1px 0px;
                      text-align: right;
                      font-weight: 600;
                    "
                  >
                    {{ formatCurrency(fundSecData.TotalMoneyNotApcept) }}
                  </td>
                </tr>
                 <tr style="display:none">
                  <td
                    style="
                      padding: 5px;
                      border: 1px #555 solid;
                      border-width: 0px 1px 1px 0px;
                      text-align: right;
                      font-weight: 600;
                    "
                    colspan="3"
                  >
                    Tạm ứng/Hoàn tạm ứng chờ duyệt
                  </td>
                  <td
                    style="
                      padding: 5px;
                      border: 1px #555 solid;
                      border-width: 0px 0px 1px 0px;
                      text-align: right;
                      font-weight: 600;
                    "
                  >
                    {{ formatCurrency(fundSecData.TotalMoneyTempAdvance) }}
                  </td>
                </tr>
                      <tr>
                  <td
                    style="
                      padding: 5px;
                      border: 1px #555 solid;
                      border-width: 0px 1px 1px 0px;
                      text-align: right;
                      font-weight: 600;
                    "
                    colspan="3"
                  >
                  III. Chi chờ xử lý
                  </td>
                  <td
                    style="
                      padding: 5px;
                      border: 1px #555 solid;
                      border-width: 0px 0px 1px 0px;
                      text-align: right;
                      font-weight: 600;
                    "
                  >
                    {{ formatCurrency(fundSecData.Collect) }}
                  </td>
                </tr>
                      <tr>
                  <td
                    style="
                      padding: 5px;
                      border: 1px #555 solid;
                      border-width: 0px 1px 1px 0px;
                      text-align: right;
                      font-weight: 600;
                    "
                    colspan="3"
                  >
                   IV. Số tiền thiếu
                  </td>
                  <td
                    style="
                      padding: 5px;
                      border: 1px #555 solid;
                      border-width: 0px 0px 1px 0px;
                      text-align: right;
                      font-weight: 600;
                    "
                  >
                    {{ formatCurrency(SpendErrorTotal) }}
                  </td>
                </tr>
                <tr>
                  <td
                    style="
                      padding: 5px;
                      border: 1px #555 solid;
                      border-width: 0px 1px 1px 0px;
                      text-align: right;
                      font-weight: 600;
                    "
                    colspan="3"
                  >
                   V. Thu chờ xử lý
                  </td>
                  <td
                    style="
                      padding: 5px;
                      border: 1px #555 solid;
                      border-width: 0px 0px 1px 0px;
                      text-align: right;
                      font-weight: 600;
                    "
                  >
                    {{ formatCurrency(fundSecData.Spend) }}
                  </td>
                </tr>
                      <tr>
                  <td
                    style="
                      padding: 5px;
                      border: 1px #555 solid;
                      border-width: 0px 1px 1px 0px;
                      text-align: right;
                      font-weight: 600;
                    "
                    colspan="3"
                  >
                   VI. Số tiền thừa
                  </td>
                  <td
                    style="
                      padding: 5px;
                      border: 1px #555 solid;
                      border-width: 0px 0px 1px 0px;
                      text-align: right;
                      font-weight: 600;
                    "
                  >
                    {{ formatCurrency(RevErrorTotal) }}
                  </td>
                </tr>
             
                     <tr>
                  <td
                    style="
                      padding: 5px;
                      border: 1px #555 solid;
                      border-width: 0px 1px 0px 0px;
                      text-align: right;
                      font-weight: bold;
                    "
                    colspan="3"
                  >
                    VII. Quỹ thực tế so với sổ sách (VII = II - I)
                  </td>
                  <td
                    style="
                      padding: 5px;
                      border: 1px #555 solid;
                      border-width: 0px 1px 0px 0px;
                      text-align: right;
                      font-weight: bold;
                    "
                  >
                    {{
                      formatCurrency(
                        fundSecData.TotalMoneyActual-
                        fundSecData.TotalMoneyCashNote
                      )
                    }}
                  </td>
                </tr>
                <tr>
                  <td
                    style="
                      padding: 5px;
                      border: 1px #555 solid;
                      border-width: 1px 1px 1px 1px;
                      text-align: right;
                      font-weight: bold;
                    "
                    colspan="3"
                  >
                    VIII. Chênh lệch (VIII = II - I + III + IV - V - VI)
                  </td>
                  <td
                    style="
                      padding: 5px;
                      border: 1px #555 solid;
                      border-width: 1px 1px 0px 0px;
                      text-align: right;
                      font-weight: bold;
                    "
                  >
                    {{
                      formatCurrency(
                        fundSecData.TotalMoneyActual-
                        fundSecData.TotalMoneyCashNote
                        +SpendErrorTotal
                        +fundSecData.Collect
                        -RevErrorTotal
                        -fundSecData.Spend
                      )
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
        <tr>
          <td colspan="2">Ghi chú:&nbsp; {{fundSecData.Note}}</td>
        </tr>
        
        <tr v-if="fundSecData.ReasonList.length >= 6 && fundSecData.ReasonList.length <= 8">
          <td colspan="2" style="padding-top: 10px">
            <br />
            <br />
          </td>
        </tr>
        <tr>
          <td colspan="2" style="padding-top: 15px">
            <table
              style="width: 100%; font-size: 12px"
              cellpadding="0"
              cellspacing="0"
            >
              <tbody>
                <tr>
                  <td style="padding: 4px; text-align: center">
                    <strong>Thủ quỹ</strong>
                    <p style="padding-bottom: 50px">
                      <em>(Ký và ghi rõ họ tên)</em>
                    </p>
                  </td>
                  <td
                    style="
                      padding: 4px;
                      border-width: 0px 1px 0px 0px;
                      text-align: center;
                    "
                  >
                    <strong>Người kiểm kê</strong>
                    <p style="padding-bottom: 50px">
                      <em>(Ký và ghi rõ họ tên)</em>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import ConvertDateTime from "@/helpers/ConvertDatetime.vue";
export default {
  props: ["fundSecData", "timeStr","lstTableSpendError","lstTableRevError","collect","spend","RevErrorTotal","SpendErrorTotal"],
  data(){
    return{
      ConvertDateTime:ConvertDateTime,
      fontWeight:'bold'
      
    }
  },
  mounted(){

  }
};
</script>
<style scoped>
.fontWeight{
  font-weight: bold;
}
</style>
