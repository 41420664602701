<template>
    <div class="main-right-content">
        <div class="header">
          <img class="btn-back" src="@/assets/img/prev.svg" alt @click="onBack" />
          <p>{{ Title }}</p>
        </div>
        <div class="content">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    props: ['Title'],
    methods: {
        onBack() {
            this.$emit('onBack');
        }
    }
}
</script>