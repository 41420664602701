<template>
  <div class="image-container">
    <img
      class="image-thumbnail"
      @click="isZoom = true"
      :src="Path"
      :data-source="Path"
    />
    <div class="btn-clear" @click="$emit('deleteFile', file)">
        <b-icon
                icon="minus-circle"
                type="is-danger" >
            </b-icon>
    </div>
    
  </div>
</template>

<script>
//var bucketName = process.env.VUE_APP_AWS_BucketName;
var bucketName = process.env.VUE_APP_AWS_BucketName;
var amzUrl = process.env.VUE_APP_AWS_URL;
// import FundTransferService from "@/modules/fundtransfers/services/FundServices.vue";
//import UploadAWS from "@/helpers/UploadAWS.vue";
// import UserService from "@/modules/user/services/UserService.vue";
// import store from "@/store";
import SignUrl from "@/helpers/SignUrl.vue";
import ToastHelper from "@/helpers/OpenToast.vue";

export default {
  
  props: ["file", "idx"],
  async mounted(){
    //this.getImage();
    var _self = this;
    let response = await SignUrl.Get_SignUrl(_self.file.Path);
      if(response != null){
          _self.Path = response;
      }else{
        ToastHelper.OpenToast(
            "Đã có lỗi xảy ra",
            "is-danger"
          );
      }
  },
  methods: {
    // async getImage() {
    //   let _self = this;
    //   var url = "https://" + _self.bucketName + "/" + _self.file.Path;
    //   let authKey = store.token;
    //   if (!authKey) {
    //     authKey = UserService.authHeader();
    //   }
    //   _self.image = await FundTransferService.GetImageFromS3(url);
    // }
  },
  data() {
    return {
      bucketName: bucketName,
      amzUrl: amzUrl,
      imgUrl: "",
      isZoom: false,
      Path: ""
    };
  },
};
</script>